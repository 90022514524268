<template>
  <admin-wrapper class="admin-authenticate">
    <a-tabs v-model:activeKey="activeKey" @change="tabsChange">
      <a-tab-pane key="1" tab="手机号码登录">
        <a-form>
          <a-form-item v-bind="validateInfos.username">
            <a-input v-model:value="formData.username" placeholder="手机号" allowClear>
              <template #prefix>
                <img src="@admin/assets/username.png" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item name="password" v-bind="validateInfos.password">
            <a-input v-model:value="formData.password" type="password" placeholder="密码" allowClear>
              <template #prefix>
                <img src="@admin/assets/password.png" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item>
            <div class="admin-authenticate-form-btns">
              <a-checkbox v-model:checked="formData.keepSignedIn">自动登录</a-checkbox>
              <div>
                <a-button type="link" @click.prevent="registerClick">注册账户</a-button>
                <a-button type="link" @click.prevent="forgetPasswordClick">忘记密码</a-button>
              </div>
            </div>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              :loading="passWordloginLoading"
              :disabled="disabledClick"
              class="admin-authenticate-form-submit-btn"
              @click.prevent="formSubmitClick('username')"
              >登录</a-button
            >
          </a-form-item>
        </a-form>
      </a-tab-pane>
      <a-tab-pane key="2" tab="微信扫码登录" force-render>
        <div id="wechat_qrcode_container" width="368" height="368"></div>
        <!-- <img src="@admin/assets/qrcode.png" width="368" height="368" /> -->
      </a-tab-pane>
      <a-tab-pane key="3" tab="手机验证码登录">
        <a-form>
          <a-form-item v-bind="validateInfos.cellphone">
            <a-input v-model:value="formData.cellphone" placeholder="手机号" allowClear>
              <template #prefix>
                <img src="@admin/assets/username.png" />
              </template>
            </a-input>
          </a-form-item>
          <a-form-item v-bind="validateInfos.verifyCode">
            <div class="flex">
              <a-input
                v-model:value="formData.verifyCode"
                placeholder="输入4位短信验证码"
                allowClear
                :disabled="!formData.cellphone"
              >
                <template #prefix>
                  <img src="@admin/assets/password.png" />
                </template>
              </a-input>
              <a-button class="ml-[10px]" :disabled="!!verifyCodeTime" @click.prevent="formVerifyCodeClick">{{
                verifyCodeTime ? `重新获取${verifyCodeTime}s` : '获取验证码'
              }}</a-button>
            </div>
          </a-form-item>
          <a-form-item>
            <div class="admin-authenticate-form-btns">
              <a-checkbox v-model:checked="formData.keepSignedIn">自动登录</a-checkbox>
              <div>
                <a-button type="link" @click.prevent="registerClick">注册账户</a-button>
                <!-- <a-button type="link" @click.prevent="forgetPasswordClick">忘记密码</a-button> -->
              </div>
            </div>
          </a-form-item>
          <a-form-item>
            <a-button
              type="primary"
              :loading="phoneLoginLoading"
              :disabled="disabledClick"
              class="admin-authenticate-form-submit-btn"
              @click.prevent="formSubmitClick('verifyCode')"
              >登录</a-button
            >
          </a-form-item>
        </a-form>
      </a-tab-pane>
    </a-tabs>
  </admin-wrapper>
  <div
    style="
      width: 100%;
      position: fixed;
      bottom: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    "
  >
    <a style="font-size: 12px; color: #bfbfbf" href="https://www.enoch-car.com/">杭州以诺行汽车科技股份有限公司</a>
    <a style="font-size: 12px; color: #bfbfbf" href="https://beian.miit.gov.cn/">浙ICP备16038648号-2</a>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { Form, Tabs as ATabs, TabPane as ATabPane, Checkbox as ACheckbox, message } from 'ant-design-vue'
import { useRouter } from 'vue-router'

import { useAjax } from '@vue-mfe/utils'

import AdminWrapper from '@admin/components/wrapper.vue'

import type { AuthenticateDto } from '@vue-mfe/container/interface'

const router = useRouter()
const keepSignedIn =
  localStorage.getItem('KEEPSIGNEDIN') === null ? false : localStorage.getItem('KEEPSIGNEDIN') === 'Y' ? true : false
const activeKey = ref('1')

const formData = reactive<
  AuthenticateDto & {
    verifyCode: string
    cellphone: string
    keepSignedIn: boolean
  }
>(
  {
    username: '',
    cellphone: '',
    password: '',
    verifyCode: '',
    keepSignedIn: keepSignedIn
  }
)
const tabsChange = () => {
  scanQrcode()
}
const scanQrcode = () => {
  localStorage.ENOQUOTE_ROLE = 'SUPPLIER'
  // @ts-ignore
  new WxLogin(
    {
      id: 'wechat_qrcode_container',
      appid: 'wx540900745659ede1',
      scope: 'snsapi_login',
      state: '',
      redirect_uri: encodeURIComponent(location.origin + '/supplier')
    }
  )
}
const { validate, validateInfos } = Form.useForm(
  formData,
  reactive(
    {
      username: [{ required: true, message: '请填写用户名' }],
      password: [{ required: true, message: '请填写密码' }],
      cellphone: [{ required: true, message: '请填写手机号' }],
      verifyCode: [{ required: true, message: '请输入验证码' }]
    }
  )
)

let verifyCodeTime = ref(0)
const tiemStars = () => {
  verifyCodeTime.value = 60
  const timer = setInterval(
    () => {
      if (verifyCodeTime.value > 0) {
        verifyCodeTime.value--
      } else {
        clearInterval(timer)
      }
    },
    1000
  )
}
const formVerifyCodeClick = () => {
  validate('cellphone').then(
    () => {
      useAjax(
        {
          action: 'GET /supplier/security/verifycode',
          params: () => ({ cellphone: formData.cellphone, business: 'LGI' }),
          success () {
            tiemStars()
          }
        }
      )
    }
  )
}
const disabledClick = ref(false)
const loginSuccess = () => {
  message.success('登录成功，正在跳转至首页')
  disabledClick.value = true
  localStorage.ENOQUOTE_ROLE = 'SUPPLIER'
  localStorage.setItem('KEEPSIGNEDIN', formData.keepSignedIn ? 'Y' : 'N')
  setTimeout(() => router.push('/'), 2000)
}

const { run: passWordlogin, loading: passWordloginLoading } = useAjax(
  {
    action: 'POST /supplier/security/authenticate',
    params: () => ({
      username: formData.username,
      password: formData.password,
      keepSignedIn: formData.keepSignedIn
    }),
    lazy: true,
    success () {
      loginSuccess()
    }
  }
)
const { run: phoneLogin, loading: phoneLoginLoading } = useAjax(
  {
    action: 'POST /supplier/security/vfcauthenticate',
    params: () => ({
      cellphone: formData.cellphone,
      verifyCode: formData.verifyCode,
      keepSignedIn: formData.keepSignedIn
    }),
    lazy: true,
    success () {
      loginSuccess()
    }
  }
)
const formSubmitClick = (type: 'username' | 'qrcode' | 'verifyCode') => {
  switch (type) {
    case 'username':
      validate(['username', 'password']).then(
        () => {
          passWordlogin()
        }
      )
      break
    case 'verifyCode':
      validate(['cellphone', 'verifyCode']).then(
        () => {
          phoneLogin()
        }
      )
  }
}
const registerClick = () => router.push('/admin/register')
const forgetPasswordClick = () => router.push('/admin/forget')
</script>

<style scoped>
.admin-authenticate :deep(.admin-authenticate__content .ant-tabs .ant-tabs-nav-container) {
  padding: 0;
}

.admin-authenticate :deep(.admin-authenticate__content .ant-tabs .ant-tabs-nav-container .ant-tabs-nav .ant-tabs-tab) {
  margin: 0;
}
.admin-authenticate-form-btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.admin-authenticate-form-submit-btn {
  width: 100%;
}
</style>
